import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  cssVariables: true,
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          minHeight: "100vh"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#7c97b8"
    },
    secondary: {
      main: "#f50057"
    },
    background: {
      default: "#ffffff"
    },
    error: {
      main: red.A400
    },
    text: {
      primary: "#333",
      secondary: "#ffffff",
      light: "#333"
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& input": {
            color: "black"
          }
        }
      }
    }
  }
});

export const textFieldStyles = {
  backgroundColor: "#ffffff",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#ffffff",
    "& input": {
      color: "black"
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 100px #ffffff inset",
      "-webkit-text-fill-color": "black"
    },
    "&.Mui-focused": {
      backgroundColor: "#ffffff",
      "& input": {
        color: "black",
        backgroundColor: "#ffffff"
      },
      "& fieldset": {
        borderColor: "primary.main"
      }
    },
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  }
};

export default theme;

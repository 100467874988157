import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Typography, Box, Button, CircularProgress, Card, CardContent, CardMedia, Container, Breadcrumbs, Link, Modal, Divider, Chip, Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Grid from "@mui/material/Grid2"; // Updated import
import bannerImage from "../assets/banner2.jpeg";
import { fetchAllBrands, fetchProductsByBrandId } from "../utils/api";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BrandDetailPage = () => {
  const { brandId } = useParams();
  const [brands, setBrands] = useState([]); // Initialize with empty array
  const [products, setProducts] = useState([]); // Initialize with empty array
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [brand, setBrand] = useState([]); // Initialize with empty array
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState([]);
  // Get unique product types for filter options
  const getUniqueProductTypes = () => {
    const types = products.map((product) => product.product_type);
    return ["all", ...new Set(types)]; // Add 'all' option and remove duplicates
  };

  // Format the label text safely
  const formatFilterLabel = (type) => {
    if (!type) return "";
    if (type === "all") return "All";
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []); // Empty dependency array ensures it runs only once when the component is mounted
  
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const brandsFromQL = await fetchAllBrands();
        if (brandsFromQL?.getBrands) {
          setBrands(brandsFromQL.getBrands);
          const selectedBrand = brandsFromQL.getBrands.find((brand) => brand.brandId === brandId);
          setBrand(selectedBrand);
        }
        const productsFromQL = await fetchProductsByBrandId(brandId);
        if (productsFromQL) {
          setProducts(shuffleProducts(productsFromQL));
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const shuffleProducts = (brands) => {
      const shuffled = [...brands]; // Create a copy to avoid mutating original array
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
      }
      return shuffled;
    };

    loadInitialData();
  }, []); // Empty dependency array since we only want this to run once

  // Filter products when selection changes
  useEffect(() => {
    if (selectedFilter === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => product.product_type === selectedFilter);
      setFilteredProducts(filtered);
    }
  }, [selectedFilter, products]);

  const handleFilterClick = (type) => {
    setSelectedFilter(type);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  // Breadcrumb component
  const BreadcrumbNav = () => (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" sx={{ color: "primary.main" }} />}
      aria-label="breadcrumb"
      sx={{
        mb: 3,
        "& .MuiBreadcrumbs-ol": {
          flexWrap: "nowrap"
        }
      }}
    >
      <Link
        component={RouterLink}
        to="/brands"
        color="#000"
        sx={{
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline"
          }
        }}
      >
        Brands
      </Link>
      <Typography color="text.primary">{brand?.brandname || "Brand Details"}</Typography>
    </Breadcrumbs>
  );

  if (!brand) {
    return (
      <Container>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">Brand not found</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      {/* Banner Section */}
      <Box
        sx={{
          width: "100vw",
          height: { xs: "250px", sm: "250px", md: "300px" },
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
          justifyContent: "center", // Center vertically
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          marginTop: { xs: "-5vh", sm: "-5vh" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Vertical on mobile, horizontal on desktop
            gap: { xs: 2, sm: 2, md: 4 },
            p: { xs: 2, sm: 2, md: 4 },
            borderRadius: 2,
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)", // Subtle box shadow
            backgroundColor: "white",
            marginTop: { xs: "1vh", sm: "2vh" },
            //ml: { xs: -20, sm: -40, md: -60, lg: -85 },
            maxWidth: "50%", // Full width on mobile, fixed width on desktop
            height: { xs: "225px", sm: "225px", md: "250px" },
            margin: "0 auto", // Center the white box
            position: "relative", // Remove absolute positioning
            justifyContent: { xs: " center" },
            alignItems: { xs: " center" }
          }}
        >
          {/* Logo Section - Left Side */}
          {/* Logo Section - Left Side */}
          <Box
            sx={{
              width: { xs: "80px", sm: "120px", md: "200px" }, // Responsive width for the logo box
              height: "auto", // Automatically adjust the height
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Ensures content stays within the box
              flexShrink: 0,
              p: { xs: 1, md: 2 } // Padding for spacing
            }}
          >
            <img
              src={brand.logo}
              alt={brand.brandname}
              style={{
                maxWidth: "100%", // Ensure the image width does not exceed the box width
                maxHeight: "100%", // Ensure the image height does not exceed the box height
                objectFit: "contain" // Scale the logo proportionally to fit within the box
              }}
            />
          </Box>

          {/* Content Section - Right Side */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1 // Take up remaining space
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "text.light",
                display: { xs: "none", md: "block" }
              }}
            >
              {brand.brandname}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: "text.light",
                lineHeight: 1.7,
                fontSize: { xs: "0.875rem", sm: "1rem" },
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3, // Shows only 3 lines
                WebkitBoxOrient: "vertical"
              }}
            >
              {brand.description}
            </Typography>

            {/* Add additional brand information here if needed */}
          </Box>
        </Box>
      </Box>
      <Container>
        <Box sx={{ mt: 4, mb: 5 }}>
          <BreadcrumbNav />
        </Box>
      </Container>
      {/* Products area */}
      {/* Product section  */}
      <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
            mt: 0
          }}
        >
          Premium Products from {brand.brandname}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "normal",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
            mt: 1
          }}
        >
          From guilt-free indulgences to everyday essentials, explore a thoughtfully curated range of high-quality, clean-label products. Designed to meet the demands of discerning customers, our selection blends innovation, taste, and
          health seamlessly.
        </Typography>
        {/* Filter Section */}
        <Box
          sx={{
            mt: 4,
            mb: 3,
            px: { xs: 2, sm: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 2,
              color: "text.primary",
              fontWeight: "medium"
            }}
          >
            Filter by Category
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              flexWrap: "wrap",
              gap: 1,
              justifyContent: "center"
            }}
          >
            {getUniqueProductTypes().map((type) => (
              <Chip
                key={type || "unknown"}
                label={formatFilterLabel(type)}
                onClick={() => handleFilterClick(type)}
                color={selectedFilter === type ? "primary" : "default"}
                variant={selectedFilter === type ? "filled" : "outlined"}
                sx={{
                  m: 0.5,
                  "&:hover": {
                    backgroundColor: (theme) => (selectedFilter === type ? theme.palette.primary.main : theme.palette.action.hover)
                  },
                  textTransform: "capitalize"
                }}
              />
            ))}
          </Stack>
        </Box>
        {/* If using Grid from '@mui/material/Grid2' import Grid from '@mui/material/Grid2'; */}
        <Grid
          container
          spacing={3}
          sx={{
            mt: 4,
            px: { xs: 2, sm: 4 },
            mb: 4,
            justifyContent: "center"
          }}
        >
          {products.map((product, index) => (
            <Grid xs={12} sm={6} md={4} key={`product-${product.id || index}`}>
              <Card
                onClick={() => handleProductClick(product)}
                sx={{
                  height: { xs: "300px", sm: "320px", md: "350px" },
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 6
                  },
                  boxShadow: 3,
                  borderRadius: 2,
                  overflow: "hidden",
                  maxWidth: "300px",
                  mx: "auto"
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "60%",
                    width: "100%",
                    backgroundColor: "grey.100"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={product.images[0] || "https://via.placeholder.com/300x200"}
                    alt={product.brandname}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      padding: 2,
                      backgroundColor: "white"
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "center",
                      color: "text.light"
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.light",
                      textAlign: "center"
                    }}
                  >
                    {product.product_type}
                  </Typography>
                  {/* {product.body_html && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.light",
                        textAlign: "center"
                      }}
                    >
                      {product.body_html}
                    </Typography>
                  )} */}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Add the modal */}
        <ProductModal open={openModal} handleClose={handleCloseModal} product={selectedProduct} />
        {/* Call-to-Action Button */}
        {/* <Button
          variant="outlined"
          sx={{
            mt: 4,
            textTransform: "none",
            fontSize: "1rem",
            padding: "10px 20px",
            borderRadius: "20px",
            mb: 5
          }}
        >
          View all
        </Button> */}
      </Box>
      {/* End Products area */}
    </Box>
  );
};

const ProductModal = ({ open, handleClose, product }) => {
  const [mainImage, setMainImage] = useState(null); // Move this to top
  // When product changes, update mainImage
  useEffect(() => {
    if (product && product.images && product.images.length > 0) {
      setMainImage(product.images[0]);
    }
  }, [product]);
  // If no product, still render the component but return null
  if (!product) return null;

  const sanitizedDescription = DOMPurify.sanitize(product?.description || "");

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="product-modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "80%", md: "60%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto"
        }}
      >
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              color: (theme) => theme.palette.grey[800]
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3 }}>
          <Box sx={{ flex: 1 }}>
            {/* Main Image */}
            <Box sx={{ mb: 2 }}>
              <img
                src={mainImage || product.images[0]}
                alt={product.title}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "400px",
                  objectFit: "contain"
                }}
              />
            </Box>

            {/* Thumbnails */}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                mt: 2
              }}
            >
              {product.images.map((image, index) => (
                <Box
                  key={index}
                  onClick={() => handleThumbnailClick(image)}
                  sx={{
                    width: 60,
                    height: 60,
                    border: image === mainImage ? "2px solid" : "1px solid",
                    borderColor: image === mainImage ? "primary.main" : "grey.300",
                    borderRadius: 1,
                    overflow: "hidden",
                    cursor: "pointer",
                    transition: "all 0.2s",
                    "&:hover": {
                      borderColor: "primary.main",
                      transform: "scale(1.05)"
                    }
                  }}
                >
                  <img
                    src={image}
                    alt={`${product.title} thumbnail ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain"
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography id="product-modal-title" variant="h5" component="h2" gutterBottom>
              {product.title}
            </Typography>
            <Divider
              sx={{
                my: 2,
                borderColor: "primary.main",
                borderWidth: 2
              }}
            />
            <Typography variant="body1" gutterBottom>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                style={{
                  "& p": { marginBottom: "1rem" },
                  "& img": { maxWidth: "100%", height: "auto" }
                }}
              />
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};



export default BrandDetailPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, CircularProgress, Card, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid2"; // Updated import
import { fetchAllBrands } from "../utils/api";
//import Sidebar from "./Sidebar";
//import ProductCard from "./ProductCard";
import bannerImage from "../assets/banner3.jpeg";

const ExistingBrandsPage = () => {
  const [brands, setBrands] = useState([]); // Initialize with empty array
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []); // Empty dependency array ensures it runs only once when the component is mounted

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const brandsFromQL = await fetchAllBrands();
        if (brandsFromQL?.getBrands) {
          setBrands(shuffleBrands(brandsFromQL.getBrands));
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const shuffleBrands = (brands) => {
      const shuffled = [...brands]; // Create a copy to avoid mutating original array
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
      }
      return shuffled;
    };

    loadInitialData();
  }, []); // Empty dependency array since we only want this to run once

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleBrandClick = (brandId) => {
    navigate(`/brand/${brandId}`);
  };

  return (
    <Box>
      {/* Banner Section */}
      <Box
        sx={{
          width: "100vw", // Full viewport width
          height: { xs: "200px", sm: "200px", md: "300px" }, // Responsive height
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Ensure proper alignment
          left: "50%", // Center on the screen
          right: "50%",
          marginLeft: "-50vw", // Offset left margin to span the full width
          marginRight: "-50vw", // Offset right margin to span the full width
          marginTop: { xs: "-5vh", sm: "-5vh" } // Offset top margin to cover the padding
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: { xs: 1, sm: 3, md: 4 }, // Add margin on top for spacing
            ml: { xs: -20, sm: -40, md: -60, lg: -85 }
          }}
        >
          <Box sx={{ maxWidth: "720px", textAlign: "left", mt: "3" }}>
            <Typography
              variant="h3"
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" }, // Responsive font size
                textAlign: "center"
              }}
            >
              Find Brands to Sell
            </Typography>
          </Box>
          {/* <Box sx={{ maxWidth: "600px", textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#000",
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.25rem" }, // Responsive font size
                textAlign: "left",
                maxWidth: "90%" // Ensure text fits on small screens
              }}
            >
              Find brands to sell
            </Typography>
          </Box> */}
          <Box sx={{ maxWidth: "600px", textAlign: "center" }}>
            <Button
              variant="contained"
              href="/signup"
              sx={{
                backgroundColor: "primary",
                color: "#fff",
                textTransform: "none",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "0.9rem",
                "&:hover": {
                  backgroundColor: "primary"
                },
                mt: { xs: 3, sm: 5, md: 5 } // Add margin on top for spacing
              }}
            >
              Sign up to sell
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 4, mb: 4, maxWidth: "1440px" }}>
        <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "normal",
              textAlign: "left",
              color: "text.light",
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
              mt: 1
            }}
          >
            Explore a carefully curated lineup of trailblazing brands transforming the world of snacking and nourishment. From clean-label snacks to vacuum-cooked chips and products made without added sugar or artificial additives, each
            selection guarantees exceptional quality and flavor. Enrich your offerings with health-focused products that align with the values of today’s conscious consumers.
          </Typography>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            mt: 4,
            px: { xs: 2, sm: 4 },
            mb: 4,
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          {brands.map((brand, index) => (
            <Grid sx={{ justifyContent: "center" }} xs={12} sm={4} key={`brand-grid-${brand.brandId || index}`}>
              <Card
                onClick={() => handleBrandClick(brand.brandId)}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 6
                  },
                  boxShadow: 3,
                  borderRadius: 2,
                  overflow: "hidden",
                  maxWidth: "300px",
                  mx: "auto"
                }}
                key={`brand-card-${brand.brandId || index}`}
              >
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "60%",
                    width: "100%",
                    backgroundColor: "grey.100"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={brand.logo || "https://via.placeholder.com/300x200"}
                    alt={brand.brandname}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      padding: 2,
                      backgroundColor: "white"
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "center",
                      color: "text.light"
                    }}
                  >
                    {brand.brandname}
                  </Typography>
                  {brand.description && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.light",
                        textAlign: "center"
                      }}
                    >
                      {brand.description}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ExistingBrandsPage;

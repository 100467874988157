// src/App.js
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
//import { AppProvider } from "./utils/AppContext";
//import { useInitialData } from "./utils/initialdata";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import SignIn from "./pages/sign-in/SignIn";
import BrandsPage from "./pages/BrandsPage";
import BrandDetailsPage from "./pages/BrandDetailsPage";
import LockContent from "./components/LockContent";
import SellersPage from "./pages/SellersPage";
import ExistingBrandsPage from "./pages/ExistingBrandsPage";

const App = () => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    // Check authentication status when app loads
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const validTill = localStorage.getItem("validTill");

    if (isAuthenticated && validTill) {
      if (new Date().getTime() < parseInt(validTill)) {
        setIsOpen(false);
      } else {
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("validTill");
        setIsOpen(true);
      }
    }
  };
  return <>{isOpen ? <LockContent setIsOpen={setIsOpen} /> : <AppContent />}</>;
};

function AppContent() {
  //useInitialData();
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/availablebrands" element={<ExistingBrandsPage />} />
        <Route path="/sellers" element={<SellersPage />} />
        <Route path="/signup" element={<HomePage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/brand/:brandId" element={<BrandDetailsPage />} />
      </Routes>
    </Layout>
  );
}
export default App;
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "../shared-theme/AppTheme";
import { GoogleIcon, FacebookIcon } from "./CustomIcons";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  width: "100%",
  minWidth: "100%",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4)
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
  }
}));

export default function SignUp(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState("");
  const [brandnameError, setBrandnameError] = React.useState(false);
  const [brandnameErrorMessage, setBrandnameErrorMessage] = React.useState("");
  const [shopurlError, setShopurlError] = React.useState(false);
  const [shopurlErrorMessage, setShopurlErrorMessage] = React.useState("");

  const navigate = useNavigate();

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");
    const name = document.getElementById("name");
    const brandname = document.getElementById("brandname");
    const shopurl = document.getElementById("shopurl");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage("Name is required.");
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage("");
    }

    if (!brandname.value || brandname.value.length < 1) {
      setBrandnameError(true);
      setBrandnameErrorMessage("Brand or Store Name is required.");
      isValid = false;
    } else {
      setBrandnameError(false);
      setBrandnameErrorMessage("");
    }

    if (!shopurl.value || shopurl.value.length < 1) {
      setShopurlError(true);
      setShopurlErrorMessage("Shop URL is required.");
      isValid = false;
    } else {
      setShopurlError(false);
      setShopurlErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    if (nameError || brandnameError || shopurlError || emailError || passwordError) {
      event.preventDefault();
      return;
    }
    const data = new FormData(event.currentTarget);
    console.log({
      name: data.get("name"),
      brandname: data.get("brandname"),
      shopurl: data.get("shopurl"),
      email: data.get("email"),
      password: data.get("password")
    });
  };

  const handleSignIn = () => {
    navigate("/sign-in");
  }

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignUpContainer direction="column" justifyContent="space-between">
        {/* <Card variant="outlined"> */}
        <Typography component="h1" variant="h4" sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}>
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl>
            <FormLabel htmlFor="name">Full name</FormLabel>
            <TextField autoComplete="name" name="name" required fullWidth id="name" placeholder="Contact Name" error={nameError} helperText={nameErrorMessage} color={nameError ? "error" : "primary"} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="brandname">Brand name/Store</FormLabel>
            <TextField autoComplete="brandname" name="brandname" required fullWidth id="brandname" placeholder="Store Name" error={brandnameError} helperText={brandnameErrorMessage} color={brandnameError ? "error" : "primary"} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="shopurl">URL</FormLabel>
            <TextField autoComplete="shopurl" name="shopurl" required fullWidth id="shopurl" placeholder="https://www.storename.com" error={shopurlError} helperText={shopurlErrorMessage} color={shopurlError ? "error" : "primary"} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField required fullWidth id="email" placeholder="your@email.com" name="email" autoComplete="email" variant="outlined" error={emailError} helperText={emailErrorMessage} color={passwordError ? "error" : "primary"} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              required
              fullWidth
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="new-password"
              variant="outlined"
              error={passwordError}
              helperText={passwordErrorMessage}
              color={passwordError ? "error" : "primary"}
            />
          </FormControl>
          <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="I want to receive updates via email." />
          <Button type="submit" fullWidth variant="contained" onClick={validateInputs}>
            Sign up
          </Button>
        </Box>
        <Divider>
          <Typography sx={{ color: "text.secondary" }}>or</Typography>
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button fullWidth variant="outlined" onClick={() => alert("Sign up with Google")} startIcon={<GoogleIcon />}>
            Sign up with Google
          </Button>
          <Button fullWidth variant="outlined" onClick={() => alert("Sign up with Facebook")} startIcon={<FacebookIcon />}>
            Sign up with Facebook
          </Button>
          <Typography sx={{ textAlign: "center" }}>
            Already have an account?{" "}
            <Link onClick={handleSignIn} variant="body2" sx={{ alignSelf: "center" }}>
              Sign in
            </Link>
          </Typography>
        </Box>
        {/* </Card> */}
      </SignUpContainer>
    </AppTheme>
  );
}

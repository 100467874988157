// src/pages/HomePage.js
import React, { useState, useEffect } from "react";
//import { AppContext, useData } from "../utils/AppContext";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, CircularProgress, Card, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid2"; // Updated import
import bannerImage from "../assets/banner1.jpg";
import { fetchAllBrands, fetchProductsData } from "../utils/api";

// const products = [
//   { id: 1, name: "Product 1", image: "https://via.placeholder.com/150" },
//   { id: 2, name: "Product 2", image: "https://via.placeholder.com/150" },
//   { id: 3, name: "Product 3", image: "https://via.placeholder.com/150" }
// ];

const HomePage = () => {
  const [brands, setBrands] = useState([]); // Initialize with empty array
  const [products, setProducts] = useState([]); // Initialize with empty array
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const brandsFromQL = await fetchAllBrands();
        if (brandsFromQL?.getBrands) {
          setBrands(brandsFromQL.getBrands);
        }
        const productsFromQL = await fetchProductsData(15, null);
        if (productsFromQL?.getPaginatedProducts?.items) {
          setProducts(productsFromQL.getPaginatedProducts.items);
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadInitialData();
  }, []); // Empty dependency array since we only want this to run once
  
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  const handleViewAllBrands = () => {
    navigate("/availablebrands");
  };

  const getRandomArray = (arraydata, count) => {
    if (!arraydata || arraydata.length === 0) return [];
    const shuffled = [...arraydata]; // Create a copy of the array
    let currentIndex = shuffled.length;
    let randomIndex;

    // While there remain elements to shuffle
    while (currentIndex !== 0) {
      // Pick a remaining element
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // Swap it with the current element
      [shuffled[currentIndex], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[currentIndex]];
    }

    return shuffled.slice(0, count);
  };

  return (
    <Box>
      {/* Banner Section */}
      <Box
        sx={{
          width: "100vw", // Full viewport width
          height: { xs: "200px", sm: "300px", md: "400px" }, // Responsive height
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Ensure proper alignment
          left: "50%", // Center on the screen
          right: "50%",
          marginLeft: "-50vw", // Offset left margin to span the full width
          marginRight: "-50vw", // Offset right margin to span the full width
          marginTop: { xs: "-5vh", sm: "-5vh" } // Offset top margin to cover the padding
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ maxWidth: "720px", textAlign: "center" }}>
            <Typography
              variant="h3"
              sx={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" }, // Responsive font size
                textAlign: "center"
              }}
            >
              Nourishing Palate
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "600px", textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#fff",
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.25rem" }, // Responsive font size
                textAlign: "center",
                maxWidth: "90%" // Ensure text fits on small screens
              }}
            >
              Celebrate authentic tastes, traditions, and innovations. Explore clean-label products sourced from India’s most passionate and creative brands.
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "600px", textAlign: "center" }}>
            <Button
              variant="contained"
              href="/signup"
              sx={{
                backgroundColor: "primary",
                color: "#fff",
                textTransform: "none",
                borderRadius: "20px",
                padding: "5px 20px",
                fontSize: "0.9rem",
                "&:hover": {
                  backgroundColor: "primary"
                },
                mt: { xs: 3, sm: 5, md: 5 } // Add margin on top for spacing
              }}
            >
              Sign up to shop
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Brands Section */}
      <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } // Responsive font size
          }}
        >
          Discover Innovative Brands Committed to Clean and Healthy Living
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "normal",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
            mt: 1
          }}
        >
          Dive into a handpicked collection of innovative brands revolutionizing the way we snack and nourish. From wholesome, clean-label snacks to vacuum-cooked chips and products crafted without added sugar or artificial adulterants,
          each offering promises uncompromised quality and taste. Elevate your shelves with health-conscious choices that resonate with today’s mindful consumers.
        </Typography>
        {/* Categories */}
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", mt: 2, gap: 2 }}>
          {["Snacks", "Chips", "Flours", "Ready-mix", "Dates Based", "Vaccum cooked"].map((category) => (
            <Button
              key={category}
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                fontSize: "0.9rem",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "primary.light"
                }
              }}
            >
              {category}
            </Button>
          ))}
        </Box>
        {/* Brands List */}
        {/* If using Grid from '@mui/material/Grid2' import Grid from '@mui/material/Grid2'; */}
        <Grid
          container
          spacing={3}
          sx={{
            mt: 4,
            px: { xs: 2, sm: 4 },
            mb: 4
          }}
        >
          {getRandomArray(brands, 3).map((brand, index) => (
            <Grid xs={12} sm={6} md={4} key={`brand-${brand.brandId || index}`}>
              <Card
                onClick={() => navigate(`/brand/${brand.brandId}`)}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 6
                  },
                  boxShadow: 3,
                  borderRadius: 2,
                  overflow: "hidden",
                  maxWidth: "300px",
                  mx: "auto"
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "60%",
                    width: "100%",
                    backgroundColor: "grey.100"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={brand.logo || "https://via.placeholder.com/300x200"}
                    alt={brand.brandname}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      padding: 2,
                      backgroundColor: "white"
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "center",
                      color: "text.light"
                    }}
                  >
                    {brand.brandname}
                  </Typography>
                  {brand.description && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.light",
                        textAlign: "center"
                      }}
                    >
                      {brand.description}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Call-to-Action Button */}
        <Button
          variant="outlined"
          onClick={handleViewAllBrands}
          sx={{
            mt: 4,
            textTransform: "none",
            fontSize: "1rem",
            padding: "10px 20px",
            borderRadius: "20px",
            mb: 5
          }}
        >
          View all
        </Button>
      </Box>
      {/* End Brands Section */}
      {/* Product section  */}
      <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Responsive font size
            mt: 2
          }}
        >
          Elevate Your Inventory with Premium Products
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "normal",
            textAlign: "left",
            color: "text.light",
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
            mt: 1
          }}
        >
          From guilt-free indulgences to everyday essentials, explore a thoughtfully curated range of high-quality, clean-label products. Designed to meet the demands of discerning customers, our selection blends innovation, taste, and
          health seamlessly.
        </Typography>
        {/* Categories */}
        {/* <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", mt: 2, gap: 2 }}>
          {["Snacks", "Chips", "Flours", "Ready-mix", "Dates Based", "Vaccum cooked"].map((category) => (
            <Button
              key={category}
              variant="outlined"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                fontSize: "0.9rem",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "primary.light"
                }
              }}
            >
              {category}
            </Button>
          ))}
        </Box> */}
        {/* Brands List */}
        {/* If using Grid from '@mui/material/Grid2' import Grid from '@mui/material/Grid2'; */}
        <Grid
          container
          spacing={3}
          sx={{
            mt: 4,
            px: { xs: 2, sm: 4 },
            mb: 4
          }}
        >
          {getRandomArray(products, 3).map((product, index) => (
            <Grid xs={12} sm={6} md={4} key={`product-${product.id || index}`}>
              <Card
                sx={{
                  height: { xs: "300px", sm: "320px", md: "350px" },
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 6
                  },
                  boxShadow: 3,
                  borderRadius: 2,
                  overflow: "hidden",
                  maxWidth: "300px",
                  mx: "auto"
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "60%",
                    width: "100%",
                    backgroundColor: "grey.100"
                  }}
                >
                  <CardMedia
                    component="img"
                    image={product.images[0] || "https://via.placeholder.com/300x200"}
                    alt={product.brandname}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      padding: 2,
                      backgroundColor: "white"
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "center",
                      color: "text.light"
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.light",
                      textAlign: "center"
                    }}
                  >
                    {product.product_type}
                  </Typography>
                  {/* {product.body_html && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.light",
                        textAlign: "center"
                      }}
                    >
                      {product.body_html}
                    </Typography>
                  )} */}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Call-to-Action Button */}
        <Button
          variant="outlined"
          sx={{
            mt: 4,
            textTransform: "none",
            fontSize: "1rem",
            padding: "10px 20px",
            borderRadius: "20px",
            mb: 5
          }}
        >
          View all
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;

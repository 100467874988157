import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, CircularProgress, Card, CardContent, CardMedia, List, ListItem } from "@mui/material";
import Grid from "@mui/material/Grid2"; // Updated import
import { fetchAllBrands } from "../utils/api";
//import Sidebar from "./Sidebar";
//import ProductCard from "./ProductCard";
import bannerImage from "../assets/banner-seller-page-1.jpeg";
import howitworks from "../assets/Brands-How-it-works.png";
import signupimage from "../assets/seller-page-content-2.jpeg";
import shoppinglady from "../assets/seller-page-content-1.jpeg";
import FAQSectionMicroRetailer from "../components/FAQSectionMicroRetailer";
import SignUp from "./sign-up/SignUp";

const BrandsPage = () => {
  const [brands, setBrands] = useState([]); // Initialize with empty array
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []); // Empty dependency array ensures it runs only once when the component is mounted

  // useEffect(() => {
  //   const loadInitialData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const brandsFromQL = await fetchAllBrands();
  //       if (brandsFromQL?.getBrands) {
  //         setBrands(brandsFromQL.getBrands);
  //       }
  //     } catch (error) {
  //       setError(error.message);
  //       console.error("Error fetching initial data:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   loadInitialData();
  // }, []); // Empty dependency array since we only want this to run once

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  // const handleBrandClick = (brandId) => {
  //   navigate(`/brand/${brandId}`);
  // };

  return (
    <Box>
      {/* Banner Section */}
      <Box
        sx={{
          width: "100vw", // Full viewport width
          height: { xs: "300px", sm: "300px", md: "450px" }, // Responsive height
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Ensure proper alignment
          left: "50%", // Center on the screen
          right: "50%",
          marginLeft: "-50vw", // Offset left margin to span the full width
          marginRight: "-50vw", // Offset right margin to span the full width
          marginTop: { xs: "-5vh", sm: "-5vh" } // Offset top margin to cover the padding
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: { xs: 2, sm: 5, md: 4 }, // Add margin on top for spacing
            ml: { xs: 0, sm: 0, md: -25, lg: -65, xl: -150 },
            maxWidth: "720px",
            textAlign: "center",
            p: 2, // Add padding for spacing"
            justifyItems: "center",
            //border: "0.5px solid #000", // Add border
            borderRadius: "10px", // Add border radius
            backgroundColor: "rgba(255, 255, 255, 0.8)" // Add background color with opacity
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#000",
              fontWeight: "bold",
              fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
              textAlign: "center"
            }}
          >
            Nourishing Palate Marketplace
          </Typography>
          {/* </Box>
          <Box sx={{ maxWidth: "600px", textAlign: "center" }}> */}
          <Typography
            variant="body1"
            sx={{
              color: "#000",
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.25rem" }, // Responsive font size
              textAlign: "center",
              mt: 2,
              maxWidth: "90%" // Ensure text fits on small screens
            }}
          >
            Expand your reach and connect with independent micro-retailers across the U.S. Join Nourishing Palate's marketplace today for seamless onboarding and access to untapped markets. We're here to support your growth and help scale
            your business with ease.
          </Typography>
          <Button
            variant="contained"
            href="/signup"
            sx={{
              backgroundColor: "primary",
              color: "#fff",
              textTransform: "none",
              borderRadius: "20px",
              padding: "5px 20px",
              fontSize: "0.9rem",
              "&:hover": {
                backgroundColor: "primary"
              },
              maxWidth: "200px", // Set maximum width for the button
              mx: "auto", // Center the button horizontally"
              mt: { xs: 3, sm: 5, md: 5 } // Add margin on top for spacing
            }}
          >
            Sign up to sell
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 4, mb: 4, maxWidth: "1440px" }}>
        <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
          <Typography
            variant="h2"
            sx={{
              color: "text.light",
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" }, // Responsive font size
              textAlign: "center"
            }}
          >
            How We Empower Your Growth
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "normal",
              textAlign: "left",
              color: "text.light",
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
              mt: 1
            }}
          >
            Join Nourishing Palate to access a curated collection of premium, clean-label brands and products. As a micro-retailer, you’ll discover a seamless way to elevate your offerings with innovative, health-focused options that
            resonate with today’s conscious consumers.
          </Typography>
        </Box>
        <Box
          sx={{
            height: { xs: "200px", sm: "200px", md: "400px" }, // Responsive height
            display: "flex",
            flexDirection: "column",
            position: "relative", // Ensure proper alignment
            // left: "50%", // Center on the screen
            // right: "50%",
            // marginLeft: "-50vw", // Offset left margin to span the full width
            // marginRight: "-50vw", // Offset right margin to span the full width
            marginTop: { xs: "5vh", sm: "5vh" }, // Offset top margin to cover the padding
            // border: "0.5px solid #CCC", // Add border
            borderRadius: "10px", // Add border radius
            justifyContent: "center"
          }}
        >
          <img src={howitworks} alt="How It Works" layout="fill" objectFit="contain" />
        </Box>
      </Box>
      <Box sx={{ mt: 4, mb: 4, maxWidth: "1440px" }}>
        <Box sx={{ textAlign: "center", mt: 4, px: 2 }}>
          <Typography
            variant="h2"
            sx={{
              color: "text.light",
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" }, // Responsive font size
              textAlign: "center",
              mb: 4
            }}
          >
            Why Choose Nourishing Palate?
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, // Single column on mobile, two columns on medium screens and up
            gap: 4,
            px: 4,
            alignItems: "center",
            border: "0.5px solid #CCC", // Add border
            borderRadius: "10px", // Add border radius
            justifyContent: "center"
          }}
        >
          {/* Left Column - Text Content */}
          <Box
            sx={{
              mt: 4,
              mb: 1,
              color: "text.light"
            }}
          >
            <Typography variant="h5" sx={{ mb: 2, color: "text.light", fontWeight: "bold" }}>
              Localized Micro-Retailers
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 4,
                  "& .MuiListItem-root": {
                    display: "list-item",
                    pl: 0,
                    mb: 1
                  },
                  color: "text.light"
                }}
              >
                <ListItem>
                  <strong>Exclusive Access to Premium Brands:</strong> Gain entry to a handpicked selection of high-quality products, including clean-label snacks, vacuum-cooked chips, and more. Every item is designed to meet the growing
                  demand for healthier choices.
                </ListItem>
                <ListItem>
                  <strong>Simplified Sourcing:</strong> Explore, select, and stock products from top-tier brands effortlessly. Nourishing Palate provides a platform that makes finding the right products for your customers quick and easy.
                </ListItem>
                <ListItem>
                  <strong>Support small and Sustainable Businesses:</strong> Be a part of the movement that supports innovative brands focused on sustainability and quality.
                </ListItem>
              </List>
            </Typography>
            {/* Add more Typography components or other content as needed */}
          </Box>

          {/* Right Column - Image */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              component="img"
              src={shoppinglady}
              alt="Localized Micro-Retailers"
              sx={{
                mb: { xs: 2, sm: 4, md: 4, lg: 4, xl: 4 },
                maxWidth: "100%",
                height: "auto",
                borderRadius: 2 // Optional: adds rounded corners
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* FAQ */}
      <FAQSectionMicroRetailer />
      {/* Signup */}
      <Box sx={{ mt: 4, mb: 4, maxWidth: "1440px", mx: "auto" }}>
        <Box
          sx={{
            display: "flex", // Changed to flex from grid
            gap: 1,
            px: 1,
            border: "0.5px solid #CCC",
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
            minHeight: "400px",
            maxHeight: "800px",
            backgroundImage: `url(${signupimage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        >
          {/* SignUp Form with overlay */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 1, sm: 4 },
              width: { xs: "90%", sm: "50%", md: "40%" },
              position: "relative",
              marginLeft: "auto", // This pushes the box to the right
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.9)"
            }}
          >
            <SignUp />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BrandsPage;

import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const FAQSectionMicroRetailer = () => {
  const [expanded, setExpanded] = useState(false);

  // Handle accordion change
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const faqData = [
    {
      question: "How do I become a micro-retailer on Nourishing Palate?",
      answer:
        "Joining is easy! Sign up through our website, complete your retailer profile, and gain instant access to our marketplace. Once approved, you can start browsing and sourcing products from our curated collection of premium brands."
    },
    {
      question: "What types of products can I source from Nourishing Palate?",
      answer:
        "Nourishing Palate offers a range of clean-label, health-focused products, including snacks, vacuum-cooked chips, and items with no added sugar or artificial additives. Each product is carefully selected to meet high-quality standards."
    },
    {
      question: "How does the ordering process work?",
      answer: "Simply browse the marketplace, add desired products to your cart, and place your order. We’ll coordinate with the brands to ensure smooth fulfillment and delivery to your store."
    },
    {
      question: "What support does Nourishing Palate provide to micro-retailers?",
      answer: "We provide end-to-end support, including access to a user-friendly dashboard, marketing insights, and dedicated customer service. Our goal is to make sourcing unique products simple and efficient for your store."
    },
    {
      question: "Can I order products in bulk?",
      answer: "Yes, you can! Our platform is designed to accommodate bulk orders, allowing you to stock up on the products your customers love while benefiting from streamlined ordering and delivery."
    },
    {
      question: "How do I track my orders?",
      answer: "You can track all your orders in real-time using the Nourishing Palate dashboard. Receive updates on order status, expected delivery times, and inventory availability."
    },
    {
      question: "Are there any fees for joining the Nourishing Palate marketplace?",
      answer: "There are no upfront fees for joining. You only pay for the products you order, with access to competitive pricing and exclusive deals from our partner brands."
    },
    {
      question: "How do I get in touch with Nourishing Palate for assistance?",
      answer: "You can reach out to our support team through our contact page or email us at [support@nourishingpalate.com]. We’re here to help with any questions or concerns."
    }
  ];

  return (
    <Box
      sx={{
        maxWidth: "1440px",
        mx: "auto",
        px: 4,
        my: 6,
        "& .MuiAccordion-root": {
          mb: 2,
          "&:before": {
            display: "none"
          }
        },
        "& .MuiAccordionSummary-root": {
          backgroundColor: "background.paper",
          borderRadius: 1
        },
        "& .MuiAccordionDetails-root": {
          px: 3,
          py: 2
        }
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          mb: 4,
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
          fontWeight: "bold",
          color: "text.light"
        }}
      >
        Just To Clarify
      </Typography>
      {/* FAQ Items */}
      {faqData.map((faq, index) => (
        <>
          <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ color: "text.light" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <Typography sx={{ fontWeight: "bold" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </Box>
  );
};

export default FAQSectionMicroRetailer;

import React, { useState } from "react";
import { AppBar, Toolbar, Box, Button, Typography, TextField, InputAdornment, IconButton, Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const Header = ({ isAuthenticated, onLogout }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleMarketplace = () => {
    navigate("/availablebrands");
  }
  const handleBrands = () => {
    navigate("/brands");
  };
  const handleSellers = () => {
    navigate("/sellers");
  }

  const handleLogout = () => {
    onLogout();
    navigate("/");
  };

  const toggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "1px solid #ddd"
      }}
    >
      {/* Main toolbar */}
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px"
        }}
      >
        {/* Logo */}
        <Box
          component="a"
          href="/"
          sx={{
            display: "flex",
            alignItems: "center",
            height: { xs: "50px", sm: "75px" }
          }}
        >
          <Box
            component="img"
            src="/logo-np.png"
            alt="logo"
            sx={{
              width: "auto",
              height: "100%",
              objectFit: "contain"
            }}
          />
        </Box>

        {/* Search bar (hidden on small screens) */}
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: "500px",
            mx: 3,
            display: { xs: "none", sm: "block" } // Hide on extra-small screens
          }}
        >
          <TextField
            fullWidth
            placeholder='Search for "Dates based snacks" or "Healthy Namkeens"'
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { borderRadius: "20px" }
            }}
          />
        </Box>

        {/* Navigation links and button */}
        <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
          <Typography
            variant="body2"
            component="a"
            onClick={handleMarketplace}
            sx={{
              textDecoration: "none",
              color: "#333",
              mx: 1.5,
              fontSize: "0.9rem",
              "&:hover": { textDecoration: "underline", cursor: "pointer" }
            }}
          >
            Marketplace
          </Typography>
          <Typography
            variant="body2"
            component="a"
            onClick={handleBrands}
            sx={{
              textDecoration: "none",
              color: "#333",
              mx: 1.5,
              fontSize: "0.9rem",
              "&:hover": { textDecoration: "underline", cursor: "pointer" }
            }}
          >
            Brands
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="/sellers"
            sx={{
              textDecoration: "none",
              color: "#333",
              mx: 1.5,
              fontSize: "0.9rem",
              "&:hover": { textDecoration: "underline", cursor: "pointer" }
            }}
          >
            Sellers
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="/signin"
            sx={{
              textDecoration: "none",
              color: "#333",
              mx: 1.5,
              fontSize: "0.9rem",
              "&:hover": { textDecoration: "underline", cursor: "pointer" }
            }}
          >
            Sign in
          </Typography>
          <Button
            variant="contained"
            href="/signup"
            sx={{
              backgroundColor: "primary",
              color: "#fff",
              textTransform: "none",
              borderRadius: "20px",
              padding: "5px 20px",
              fontSize: "0.9rem",
              "&:hover": {
                backgroundColor: "primary",
                cursor: "pointer"
              },
              ml: 2
            }}
          >
            Sign up to shop
          </Button>
        </Box>

        {/* Mobile Menu Icon */}
        <IconButton
          edge="end"
          color="black"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{ display: { xs: "block", sm: "none" } }} // Show only on extra-small screens
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer for Mobile View */}
      <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <List>
            <ListItem
              button
              component="a"
              onClick={handleMarketplace}
              sx={{
                textDecoration: "none",
                color: "#333",
                mx: 1.5,
                fontSize: "0.9rem",
                "&:hover": { textDecoration: "underline", cursor: "pointer" }
              }}
            >
              <ListItemText primary="Marketplace" />
            </ListItem>
            <ListItem
              button
              component="a"
              onClick={handleBrands}
              sx={{
                textDecoration: "none",
                color: "#333",
                mx: 1.5,
                fontSize: "0.9rem",
                "&:hover": { textDecoration: "underline", cursor: "pointer" }
              }}
            >
              <ListItemText primary="Brands" />
            </ListItem>
            <ListItem
              button
              component="a"
              onClick={handleSellers}
              sx={{
                textDecoration: "none",
                color: "#333",
                mx: 1.5,
                fontSize: "0.9rem",
                "&:hover": { textDecoration: "underline", cursor: "pointer" }
              }}
            >
              <ListItemText primary="Sellers" />
            </ListItem>
            <Divider />
            <ListItem
              button
              component="a"
              href="/signin"
              sx={{
                textDecoration: "none",
                color: "#333",
                mx: 1.5,
                fontSize: "0.9rem",
                "&:hover": { textDecoration: "underline", cursor: "pointer" }
              }}
            >
              <ListItemText primary="Sign in" />
            </ListItem>
            <ListItem
              button
              component="a"
              href="/signup"
              sx={{
                textDecoration: "none",
                color: "#333",
                mx: 1.5,
                fontSize: "0.9rem",
                "&:hover": { textDecoration: "underline", cursor: "pointer" }
              }}
            >
              <ListItemText primary="Sign up to shop" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;

import { useState, useEffect } from "react";
import { AppBar, Toolbar, Box, TextField, Button, Container, Paper, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LockContent = ({ setIsOpen }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const lockpassword = process.env.REACT_APP_LOCK_PASSWORD;

  useEffect(() => {
    // Check authentication status when component mounts
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const validTill = localStorage.getItem("validTill");

    if (isAuthenticated && validTill) {
      // Check if the authentication is still valid
      if (new Date().getTime() < parseInt(validTill)) {
        setIsOpen(false);
      } else {
        // Clear expired authentication
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("validTill");
        setIsOpen(true);
      }
    }
  };

  const setAuthenticationWithExpiry = () => {
    const now = new Date().getTime();
    const sixHoursInMs = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    const validTill = now + sixHoursInMs;

    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("validTill", validTill.toString());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === lockpassword) {
      setAuthenticationWithExpiry();
      setIsOpen(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #ddd"
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px"
          }}
        >
          <Box
            component="a"
            href="/"
            sx={{
              display: "flex",
              alignItems: "center",
              height: { xs: "50px", sm: "75px" }
            }}
          >
            <Box
              component="img"
              src="/logo-np.png"
              alt="logo"
              sx={{
                width: "auto",
                height: "100%",
                objectFit: "contain"
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: "100%",
            maxWidth: 400
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2
              }}
            >
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Enter password"
                variant="outlined"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button type="submit" variant="contained" fullWidth size="large" disabled={!password} sx={{ mt: 2 }}>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default LockContent;

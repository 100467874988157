import axios from "axios";

const apiBaseURL = process.env.REACT_APP_APIBASE_URL;
const apiLoginURL = process.env.REACT_APP_APILOGIN_URL;
const environment = process.env.REACT_APP_API_STAGE;
const API_key = process.env.REACT_APP_API_KEY;
const apiGraphQLURL = process.env.REACT_APP_APIGRAPHQL_URL;
const apiGraphQLKey = process.env.REACT_APP_APIGRAPHQL_KEY;

export const GET_BRANDS_QUERY = `
  query {
    getBrands {
        brandId
        brandname
        description
        logo
    }
  }
`;


export const GET_PRODUCTS_QUERY = `
  query GetPaginatedProducts($limit: Int, $nextToken: String) {
    getPaginatedProducts(limit: $limit, nextToken: $nextToken) {
      items {
        productId
        brandId
        brandname
        title
        images
      }
      nextToken
    }
  }
`;

export const GET_PRODUCTS_BY_BRAND_QUERY = `
      query GetProductsByBrandId($brandId: ID!) {
        getProductsByBrandId(brandId: $brandId) {
          productId
          title
          description
          images
          brandname
          brandId
        }
      }
    `;

const apiGraphQLClient = axios.create({
  baseURL: apiGraphQLURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": apiGraphQLKey
  }
});

export const fetchProductsData = async (limit, nextToken) => {
  try {
    const {
      data: { data }
    } = await apiGraphQLClient.post("", {
      query: GET_PRODUCTS_QUERY,
      variables: {
        limit: limit,
        nextToken: nextToken
      }
    });
    return data;
  } catch (error) {
    console.error("fetchProductsData request failed:", error);
    throw error;
  }
};


export const fetchAllBrands = async () => {
  try {
    const {
      data: { data }
    } = await apiGraphQLClient.post("", {
      query: GET_BRANDS_QUERY
    });
    return data;
  } catch (error) {
    console.error("GraphQL request failed:", error);
    throw error;
  }
};

export const fetchGraphQLData = async (query) => {
  try {
    const {
      data: { data }
    } = await apiGraphQLClient.post("", {
      query: query
    });
    return data;
  } catch (error) {
    console.error("GraphQL request failed:", error);
    throw error;
  }
};

export const fetchProductsByBrandId = async (brandId) => {
  try {
    const {
      data: { data }
    } = await apiGraphQLClient.post("", {
      query: GET_PRODUCTS_BY_BRAND_QUERY,
      variables: {
        brandId: brandId
      }
    });
    return data?.getProductsByBrandId;
  } catch (error) {
    console.error("fetchProductsByBrandId request failed:", error);
    throw error;
  }
}; 


// Create an Axios instance
const apiClient = axios.create({
  baseURL: apiBaseURL + environment, // Replace with your API Gateway URL
  timeout: 10000, // Set timeout if needed
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": API_key
  }
});

const apiLoginClient = axios.create({
  baseURL: apiLoginURL + environment, // Replace with your API Gateway URL
  timeout: 10000, // Set timeout if needed
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": API_key
  }
});

// Add interceptors for requests and responses (optional)
// apiClient.interceptors.request.use(
//   (config) => {
//     // Add authentication token or API key to headers
//     const token = localStorage.getItem("accessToken"); // Example token from localStorage
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//       config.headers["X-Api-Key"] = API_key;
//       config.headers["Content-Type"] = "application/json";
//     }
//     console.log("Request Headers:", config.headers);
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle common errors globally
//     console.error("API Error:", error.response?.data || error.message);
//     return Promise.reject(error);
//   }
// );

// Define reusable API calls
export const login = async (username, password) => {
  console.log("login: ", username, password);
  console.log("apiLoginURL: ", apiLoginURL);
  return apiLoginClient.post("/login", { username, password });
};

export const fetchData = async (endpoint) => {
  return apiClient.get(endpoint);
};

export const postData = async (endpoint, data) => {
  return apiClient.post(endpoint, data);
};

export const updateData = async (endpoint, data) => {
  return apiClient.put(endpoint, data);
};

export const deleteData = async (endpoint) => {
  return apiClient.delete(endpoint);
};

// Export the Axios instance if you need custom calls
export default apiClient;

import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const FAQSection = () => {
    const [expanded, setExpanded] = useState(false);

    // Handle accordion change
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const faqData = [
      {
        question: "How do I get started selling on Nourishing Palate?",
        answer: "Getting started is simple! Sign up through our website, complete your brand profile, and upload your product catalog. Our team will review your application and guide you through the onboarding process."
      },
      {
        question: "What types of products can I sell on the marketplace?",
        answer:
          "We are focused on clean-label, healthy products and welcome a variety of snacks and food products that meet our quality standards. This includes packaged foods, beverage mixes, snacks, and specialty items. All products must comply with FDA regulations and local food safety requirements."
      },
      {
        question: "How does the micro-retailer fulfillment process work?",
        answer:
          "Once our network of micro-retailers requests for the product, we will review and place the fulfillment request with the brands. You're responsible for packaging and shipping products within the agreed timeframe. We provide tools to manage orders and track inventory. efficiently."
      },
      {
        question: "What support does Nourishing Palate provide to sellers?",
        answer:
          "We provide comprehensive support including dedicated account management, marketing tools, analytics dashboard, inventory management system, and regular training sessions. Our team is always available to help you optimize your marketplace presence."
      },
      {
        question: "How can I manage my inventory and pricing?",
        answer: "Our seller dashboard provides easy-to-use tools for managing your inventory, updating prices, and tracking stock levels in real-time. You can set up automated alerts for low stock, manage multiple product variations, and adjust pricing as needed."
      },
      {
        question: "How do I get paid for my sales?",
        answer: "We offer a range of payment options including credit card, bank transfer, and direct deposit. We also provide a comprehensive reporting system to track sales and revenue."
      },
      {
        question: "How do I manage my account?",
        answer: "You can manage your account through the Nourishing Palate dashboard. You can update your brand profile, manage your products, and view sales and revenue data. We also provide support for account management and billing."
      },
      {
        question: "How do I get in touch with the Nourishing Palate team?",
        answer: "You can reach out to us through our contact page or email us at XXXXXXXXXXXXXXXXXXXXXXXXXXXX. We're here to help you with any questions or concerns you may have."
      }
    ];
  return (
    <Box
      sx={{
        maxWidth: "1440px",
        mx: "auto",
        px: 4,
        my: 6,
        "& .MuiAccordion-root": {
          mb: 2,
          "&:before": {
            display: "none"
          }
        },
        "& .MuiAccordionSummary-root": {
          backgroundColor: "background.paper",
          borderRadius: 1
        },
        "& .MuiAccordionDetails-root": {
          px: 3,
          py: 2
        }
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          mb: 4,
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
          fontWeight: "bold",
          color: "text.light"
        }}
      >
        Just To Clarify
      </Typography>
      {/* FAQ Items */}
      {faqData.map((faq, index) => (
        <>
          <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ color: "text.light" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
              <Typography sx={{ fontWeight: "bold" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </Box>
  );
};

export default FAQSection;

// src/index.js
import React from "react";
import { createRoot } from "react-dom/client"; // Changed this line
import { CssBaseline, ThemeProvider } from "@mui/material";
import App from "./App";
import theme from "./theme";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root")); // Changed this line
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
// src/components/Layout.js
import React from "react";
import { Box, Container } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh" // Full height of the viewport
      }}
    >
      <Header />
      <Container
        maxWidth="lg"
        component="main"
        sx={{ flexGrow: 1, mt: 4 }} // Ensure main content grows to fill space
      >
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
